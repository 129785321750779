import { Box, Center, Heading, HStack, Pressable, Stack, Text } from 'native-base';
import { DealResponse } from '../../api/api';
import { hardShadow } from '../../constants/shadow';
import { ArrowRightIcon } from '../Icons/Arrow-right';
import { DealCardDiscountBadge, DealCardImage } from './DealCardImage';
import DealSpotCaption from './DealSpotCaption';

const formatter = new Intl.NumberFormat('de-DE', {
  style: 'currency',
  currency: 'EUR',
});

export interface DealCardProps {
  deal: DealResponse;
  width?: number;
  showDescription?: boolean;
  showSpotCaption?: boolean;
  onClick?: (id: string) => void;
}

export default function DealCard({
  deal,
  width = 320,
  showDescription = true,
  showSpotCaption = true,
  onClick,
}: DealCardProps) {
  return (
    <Pressable onPress={() => onClick?.(deal.id)}>
      <Box w={width} rounded={'2xl'} backgroundColor={'white'} style={hardShadow}>
        <DealCardImage media={deal.media} type={deal.type}>
          <DealCardDiscountBadge discountPercent={deal.discountPercent}></DealCardDiscountBadge>
        </DealCardImage>
        <Stack p={showSpotCaption ? 2 : 4} space={3}>
          <Heading size={'sm'} height={'10'} numberOfLines={2} isTruncated={true}>
            {deal.title}
          </Heading>
          {showDescription ? (
            <Text isTruncated numberOfLines={3} color={'city.600'} height={'16'}>
              {deal.description}
            </Text>
          ) : null}
          <HStack alignItems={'stretch'} space={4} justifyContent={'space-between'}>
            <Stack>
              <HStack space={2}>
                <Text textDecorationLine={'line-through'} color={'city.600'}>
                  {formatter.format(deal.regularPrice)}
                </Text>
                <Text color={'city.700'}>{Math.round(deal.discountPercent * 100)}%</Text>
              </HStack>
              <Text fontSize={'2xl'} color={'soda.600'}>
                {formatter.format(deal.reducedPrice)}
              </Text>
            </Stack>
            <Stack justifyContent={'flex-end'}>
              <Center rounded={'xl'} p={2} backgroundColor={'soda.600'}>
                <ArrowRightIcon width={20} height={20} color={'white'} />
              </Center>
            </Stack>
          </HStack>
        </Stack>
        {showSpotCaption && deal.spotCaption ? (
          <Stack bgColor={'city.50'} py={1} borderBottomRadius={'2xl'}>
            <DealSpotCaption spotCaption={deal.spotCaption} />
          </Stack>
        ) : null}
      </Box>
    </Pressable>
  );
}
