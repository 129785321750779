import { useFocusEffect } from '@react-navigation/native';
import { Center, ScrollView, Stack } from 'native-base';
import React, { useCallback, useEffect } from 'react';
import { Pressable } from 'react-native';
import { TicketResponse } from '../../../../api/api';
import TicketSummery from '../../../../components/Ticket/TicketSummery';
import { useAppDispatch, useAppSelector } from '../../../../store/app.hooks';
import { loadInboxThunk, resetUnreadedMessagesInboxThunk } from '../../../../store/slices/inbox.slice';
import { loadTicketThunk, setTicket } from '../../../../store/slices/ticket.slice';
import { InboxNavigationScreenProps } from './InboxNavigation';

export default function InboxScreen({ navigation }: InboxNavigationScreenProps<'Inbox'>) {
  const apiResponse = useAppSelector((state) => state.ticket.api_response);
  const inboxApiResponse = useAppSelector((state) => state.inbox.api_response);
  const dispatch = useAppDispatch();

  const openTicket = useCallback((ticket: TicketResponse) => {
    dispatch(setTicket(ticket));
    navigation.getParent()!.navigate('TicketDetail', { ticketId: ticket.id });
  }, []);

  useEffect(() => {
    dispatch(loadTicketThunk());
  }, []);

  useFocusEffect(
    useCallback(() => {
      if (inboxApiResponse?.unreadedMessages) {
        dispatch(resetUnreadedMessagesInboxThunk()).then(() => dispatch(loadInboxThunk()));
      }
    }, [inboxApiResponse]),
  );

  return (
    <ScrollView>
      <Center p={2}>
        <Stack w={'100%'} maxW={'900px'} space={2}>
          {apiResponse?.data?.map((ticket) => (
            <Pressable key={ticket.id} onPress={() => openTicket(ticket)}>
              <TicketSummery ticket={ticket}></TicketSummery>
            </Pressable>
          ))}
        </Stack>
      </Center>
    </ScrollView>
  );
}
