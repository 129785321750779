import { Center, HStack, Text, useToken } from 'native-base';
import React from 'react';
import { SpotlikedLogo } from '../../../../components/Icons/SpotlikedLogo';
import ShadowBox from '../../../../components/ShadowBox';
import { useInfluencer } from '../../../../hooks/useInfluencer';
import { useAppDispatch } from '../../../../store/app.hooks';
import { logoutThunk } from '../../../../store/slices/user.slice';
import { MainTabScreenProps } from '../../../RootNavigationProps';

export default function ProfileScreen({ navigation }: MainTabScreenProps<'profile'>) {
  const [sodaColor] = useToken('colors', ['soda.600']);
  const influencer = useInfluencer();
  const dispatch = useAppDispatch();

  async function logout() {
    await dispatch(logoutThunk());
    navigation.navigate('Login');
  }

  return (
    <Center h={'100%'} bgColor={'white'}>
      <ShadowBox buttonText="Ausloggen" onButtonPress={() => logout()}>
        <SpotlikedLogo width={200} height={60} color={sodaColor}></SpotlikedLogo>
        <HStack space={2}>
          <Text>Angemeldet als:</Text>
          <Text fontWeight={'bold'}>
            {influencer?.contact.firstName} {influencer?.contact.lastName}
          </Text>
        </HStack>
      </ShadowBox>
    </Center>
  );
}
